import React, {Component} from 'react';
import {Link , Redirect} from 'react-router-dom';
import $ from 'jquery';
import {api_url} from '../Config';
import swal from 'sweetalert';
import Header from './Header';
import Footer from './Footer';
import delivery from '../assets/images/delivery.png';
import paymentmethod from '../assets/images/payment-method.png';
import pickup from '../assets/images/pickup.png';
import shipmint from '../assets/images/shipmint.png';
import map from '../assets/images/map-img.png';
import CustomerHeader from '../component/CustomerHeader';
class PickupRequest extends Component {
constructor(){
super();
    this.state = {
        base_url:api_url.base_url,
        redirectToReferrer: false,
        redirectToNext: false,
        cities:[],
        info:[],
        sTypes:'',
        allAreas:[],
        destination_cities:[],
        origins:[],
        customer_id:localStorage.getItem('customer_id'),
        customer_city:'',
        // Details
        service_type_val:'',
        sname:'',
        sphone:'',
        origin:'',
        saddress:'',
        rname:'',
        rphone:'',
        raddress:'',
        destination:'',
        area:'',
        // Errors
        snameErr:'',
        sphoneErr:'',
        originErr:'',
        saddressErr:'',
        rnameErr:'',
        rphoneErr:'',
        raddressErr:'',
        destinationErr:'',
        areaErr:'',
        //Shipment Details
        item_detail:'',
        special_isntructions:'',
        pieces:'1',
        cod_amount:'',
        weight:'0.5',
        // shipment Errors
        item_detailErr:'',
        special_insErr:'',
        piecesErr:'',
        cod_amountErr:'',
        weightErr:'',
        loading:false,
        manual_area:"0",
        manualAreaRequired:false
    };
    this.onChange = this.onChange.bind(this);
    this.nextOne = this.nextOne.bind(this);
    this.nextPayment = this.nextPayment.bind(this);
    this.nextDelivery = this.nextDelivery.bind(this);
    this.firstStep = this.firstStep.bind(this);
    this.submitPrev = this.submitPrev.bind(this);
    this.deliveryPrev = this.deliveryPrev.bind(this);
    this.paymentPrev = this.paymentPrev.bind(this);
    this.getArea = this.getArea.bind(this);
    this.twoCalls = this.twoCalls.bind(this);
    this.submitOrder = this.submitOrder.bind(this);
}
componentWillMount() {
    if(localStorage.getItem("customer_id")){
        this.getUserData();
    }
    else{
     this.setState({redirectToReferrer: true});
    }
}
componentDidMount(){
    var customer_id = localStorage.getItem('customer_id');

    var api_cities_details = this.state.base_url+'customer_api.php?type=getCities';
    fetch(api_cities_details)
    .then(res=>res.json())
    .then(result=>{
        this.setState({
            cities:result
        })
    })

    var api_pickup_details = this.state.base_url+'customer_api.php?type=customer_info&id='+customer_id;
    fetch(api_pickup_details)
    .then(res=>res.json())
    .then(result=>{
        if (result.response==1) {

            this.setState({
                info:result.detail,
                sname:result.detail.fname,
                sphone:result.detail.mobile_no,
                saddress:result.detail.address,
                origin:result.detail.city,
                customer_city:result.detail.city,
            })

        }
    })


    var api_service_types = this.state.base_url+'customer_api.php?type=getAllServiceTypes&id='+customer_id;
    fetch(api_service_types)
    .then(res=>res.json())
    .then(result=>{
        this.setState({
            sTypes:result.sTypes
        })
    })

    var getOrigins = this.state.base_url+'customer_api.php?type=getServiceOrigins&id='+customer_id;
    fetch(getOrigins)
    .then(res=>res.json())
    .then(result=>{
        
        this.setState({ 
            manual_area:result.manual_area
        },()=>{console.log(this.state)})

    })
}

getUserData() {
    JSON.parse(localStorage.getItem("customer_id"));
}

nextOne(value){
    console.log('service type is '+value);
    this.setState({
        service_type_val:value
    },()=>{
        var customer_id = localStorage.getItem('customer_id');
         let originData = {
                customer_id:customer_id,
                service_type:this.state.service_type_val
            }
        // console.log(trackData);
          const getOriginscities = this.state.base_url+'customer_api.php?type=getServiceOrigins';
            fetch(getOriginscities,
            {
                method: 'POST',
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify(originData)
            })
            .then((response) => response.json()
                .then((result) => {
                   this.setState({
                     origins:result.origins,
                     destination_cities:result.destination,
                     manual_area:result.manual_area,
                   })
            }))
            .catch((error) => {
                this.setState({loading:false})
            });
        var active = $('.first_li');
        $('.second_li').addClass('active');
        active.removeClass('active');
        $('#step1').removeClass('active');
        $('#step2').addClass('active');
    })

}

nextPayment(){

    if (this.state.origin =='' && this.state.sname=='' && this.state.sphone=='' && this.state.saddress=='' )
    {
        this.setState({
            originErr:"Origin is required",
            snameErr:"Sender name is required",
            sphoneErr:"Sender phone is required",
            saddressErr:"Sender address is required",
        })
    }else if(this.state.origin ==''){
        this.setState({
            originErr:"Origin is required",
            snameErr:"",
            sphoneErr:"",
            saddressErr:"",
        })
    }
    else if(this.state.sname ==''){
        this.setState({
            originErr:"",
            snameErr:"Sender name is required",
            sphoneErr:"",
            saddressErr:"",
        })
    }
    else if(this.state.sphone ==''){
        this.setState({
            originErr:"",
            snameErr:"",
            sphoneErr:"Sender phone is required",
            saddressErr:"",
        })
    }
    else if(this.state.saddress ==''){
        this.setState({
            originErr:"",
            snameErr:"",
            sphoneErr:"",
            saddressErr:"Sender address is required",
        })
    }else{
        this.setState({
            originErr:"",
            snameErr:"",
            sphoneErr:"",
            saddressErr:"",
        })
        var active = $('.second_li');
        $('.third_li').addClass('active');
        active.removeClass('active');
        $('#step2').removeClass('active');
        $('#step3').addClass('active');
    }

}
nextDelivery(){
    if (this.state.destination =='' && this.state.rname=='' && this.state.rphone=='' &&this.state.raddress=='' ) {
        this.setState({
            destinationErr:"Destination is required",
            rnameErr:"Receiver name is required",
            rphoneErr:"Receiver phone is required",
            raddressErr:"Receiver address is required",
        })
    }else if(this.state.destination ==''){
        this.setState({
            destinationErr:"Destination is required",
            rnameErr:"",
            rphoneErr:"",
            raddressErr:"",
        })
    }
    else if(this.state.rname ==''){
        this.setState({
            destinationErr:"",
            rnameErr:"Receiver name is required",
            rphoneErr:"",
            raddressErr:"",
        })
    }
    else if(this.state.rphone ==''){
        this.setState({
            destinationErr:"",
            rnameErr:"",
            rphoneErr:"Receiver phone is required",
            raddressErr:"",
        })
    }
    else if(this.state.raddress ==''){
        this.setState({
            destinationErr:"",
            rnameErr:"",
            rphoneErr:"",
            raddressErr:"Receiver address is required",
        })
    }else{
        this.setState({
            destinationErr:"",
            rnameErr:"",
            rphoneErr:"",
            raddressErr:"",
        })
        var active = $('.third_li');
        $('.fourth_li').addClass('active');
        active.removeClass('active');
        $('#step3').removeClass('active');
        $('#complete').addClass('active');
    }

}
firstStep(){
    if (this.state.service_type_val==='') {
        swal('Please select a service type.');
    }else{
        var active = $('.first_li');
        $('.second_li').addClass('active');
        active.removeClass('active');
        $('#step1').removeClass('active');
        $('#step2').addClass('active');
    }
}
submitPrev(){
    var active = $('.third_li');
    $('.fourth_li').removeClass('active');
    active.addClass('active');
    $('#step3').addClass('active');
    $('#complete').removeClass('active');
}
deliveryPrev(){
    var active = $('.second_li');
    $('.third_li').removeClass('active');
    active.addClass('active');
    $('#step2').addClass('active');
    $('#step3').removeClass('active');
}
paymentPrev(){
    var active = $('.first_li');
    $('.second_li').removeClass('active');
    active.addClass('active');
    $('#step1').addClass('active');
    $('#step2').removeClass('active');
}
twoCalls(e)
{
    this.onChange(e);
    this.getArea(e);
}
getArea(e)
{
    var name = e.target.value;
    var area_api = this.state.base_url+'customer_api.php?type=get_areas&cityname='+name;
    fetch(area_api)
    .then(res=>res.json())
    .then(result=>{
        if (result.response==1) {
            this.setState({
                allAreas:result.areas,
                area:result.areas[0].id
            })
        }else{
           this.setState({
                allAreas:[],
                area:null
            }) 
        }
    })
}

submitOrder(e)
{
    e.preventDefault();

    if (this.state.item_detail =='' && this.state.pieces=='' && this.state.weight==''&& this.state.cod_amount=='' ) {
        this.setState({
            item_detailErr:"Detail is required", 
            piecesErr:"Please Enter No. of pieces", 
            weightErr:"Please enter weight",
            cod_amountErr:"Please enter COD Amount",
        })
    }else if(this.state.item_detail ==''){
        this.setState({
            item_detailErr:"Detail is required", 
            piecesErr:"", 
            weightErr:"",
            cod_amountErr:"",
        })
    } 
    else if(this.state.pieces ==''){
        this.setState({
            item_detailErr:"", 
            piecesErr:"Please Enter No. of pieces", 
            weightErr:"",
            cod_amountErr:"",
        })
    }else if(this.state.weight ==''){
        this.setState({
            item_detailErr:"", 
            piecesErr:"", 
            weightErr:"Please enter weight",
            cod_amountErr:"",
        })
    }else if(this.state.cod_amount ==''){
        this.setState({
            item_detailErr:"", 
            piecesErr:"", 
            weightErr:"",
            cod_amountErr:"Please enter COD Amount",
        })
    }else{
        this.setState({
            item_detailErr:"", 
            piecesErr:"", 
            weightErr:"",
            loading:true,
        })
        const submitUrl = this.state.base_url+'customer_api.php?type=save_order';
        fetch(submitUrl,
        {
            method: 'POST',
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(this.state)
        })
        .then((response) => response.json())
        .then(result=>{
            if(result.response===1)
            {
                this.setState({redirectToNext:true});
            }
            this.setState({loading:false});
        })
        .catch((error) => {
            this.setState({loading:false});
        });
    }


}
onChange(e){
    this.setState({[e.target.name]:e.target.value},()=>{console.log(this.state)});
}
render(){
    const lang =JSON.parse(localStorage.getItem("lang"));
  if(this.state.redirectToReferrer)
  {
    return(<Redirect to='/from-me-shipment' />)
  }
  if(this.state.redirectToNext)
  {
    return(<Redirect to='/orders' />)
  }
const {cities} = this.state;
const sTypes = this.state.sTypes ? this.state.sTypes :'';
return(
<React.Fragment>
   <div className="booking_values main_body register_main track_shipment_page" id="desktop_view">
      <div className="login_screen">
         <CustomerHeader title="Booking" />
         <form>
            <div className="wizard">
               <div className="wizard-inner">
                  <div className="connecting-line"></div>
                  <ul className="nav nav-tabs" role="tablist">
                     <li role="presentation" className="first_li active">
                        <a  >
                        <span className="round-tab">
                        <img src={paymentmethod} alt=""/>
                        </span>
                        </a>
                     </li>
                     <li role="presentation" className="second_li disabled">
                        <a  >
                        <span className="round-tab">
                        <img src={pickup} alt=""/>
                        </span>
                        </a>
                     </li>
                     <li role="presentation" className=" third_li disabled">
                        <a >
                        <span className="round-tab">
                        <img src={delivery} alt=""/>
                        </span>
                        </a>
                     </li>
                     <li role="presentation" className=" fourth_li disabled">
                        <a  title="Complete">
                        <span className="round-tab">
                        <img src={shipmint} alt=""/>
                        </span>
                        </a>
                     </li>
                  </ul>
               </div>

                  <div className="tab-content-">
                  <div className="carge_location_map"><img src={map} alt=""/></div>
                     <div className="tab-pane active" role="tabpanel" id="step1">
                        <div className="shipmentbox">
                            { (sTypes !='') ?
                                sTypes.map((value,index)=>{
                                    return(
                                        <div key={index} className="payment_method_box" onClick={()=>this.nextOne(value.id)}>
                                        <input type="radio"  name="sType" checked={value.id==this.state.service_type_val} value={value.id}/>
                                        <label>{value.service_type}</label>
                                    </div>
                                        );
                                })
                                :
                                <div className="payment_method_box">
                                  <input type="radio" id="sType" name="sType" disabled={true} value=''/>
                                  <label htmlFor="sType">{lang.service_type}</label>
                              </div>
                            }

                            </div>

                            <ul className="list-inline ">
                               <li>
                               <button type="button" className="next-step next-step_pay" onClick={this.firstStep}>{lang.next}</button></li>
                            </ul>
                     </div>
                     <div className="tab-pane" role="tabpanel" id="step2">
                        <div className="shipmentbox">
                              <div className="pickup_information">
                                  <h4>{lang.pickup_detail}</h4>
                                  <div className="inner_pickupinfo">

                                    <label><span>*</span> {lang.city_area}<h6>{this.state.originErr}</h6></label>
                                    <select className="form-control origin" name="origin" onChange={this.onChange}>
                                        { (this.state.origins) ?
                                            this.state.origins.map((value,index)=>{
                                                var select_city = false;
                                                if (value.origin==this.state.info.city) {
                                                    select_city =true;
                                                }
                                                return(
                                                   <option key={index} selected={select_city} value={value.origin}>{value.origin}</option>
                                                );
                                            })
                                            :
                                            <option></option>
                                        }
                                    </select>
                                  </div>
                                  <div className="inner_pickupinfo">
                                      <svg viewBox="0 0 24 24"><path d="M11.5 14c4.142 0 7.5 1.567 7.5 3.5V20H4v-2.5c0-1.933 3.358-3.5 7.5-3.5zm6.5 3.5c0-1.38-2.91-2.5-6.5-2.5S5 16.12 5 17.5V19h13v-1.5zM11.5 5a3.5 3.5 0 1 1 0 7a3.5 3.5 0 0 1 0-7zm0 1a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5z" fill="#00899b"/></svg>
                                    <label><span>*</span> {lang.name}<h6>{this.state.snameErr}</h6></label>
                                    <input type="text" name="sname" defaultValue={this.state.info.fname} placeholder={lang.name} onChange={this.onChange} />
                                  </div>
                                  <div className="inner_pickupinfo">
                                    <svg  viewBox="0 0 24 24"><path d="M19.5 22c.827 0 1.5-.673 1.5-1.5V17c0-.827-.673-1.5-1.5-1.5c-1.17 0-2.32-.184-3.42-.547a1.523 1.523 0 0 0-1.523.363l-1.44 1.44a14.655 14.655 0 0 1-5.885-5.883L8.66 9.436c.412-.382.56-.963.384-1.522A10.872 10.872 0 0 1 8.5 4.5C8.5 3.673 7.827 3 7 3H3.5C2.673 3 2 3.673 2 4.5C2 14.15 9.85 22 19.5 22zM3.5 4H7a.5.5 0 0 1 .5.5c0 1.277.2 2.531.593 3.72a.473.473 0 0 1-.127.497L6.01 10.683c1.637 3.228 4.055 5.646 7.298 7.297l1.949-1.95a.516.516 0 0 1 .516-.126c1.196.396 2.45.596 3.727.596c.275 0 .5.225.5.5v3.5c0 .275-.225.5-.5.5C10.402 21 3 13.598 3 4.5a.5.5 0 0 1 .5-.5z" fill="#00899b"/></svg>
                                    <label><span>*</span> {lang.phone}<h6>{this.state.sphoneErr}</h6></label>
                                    <input type="number" name="sphone" placeholder={lang.phone_number} defaultValue={this.state.info.mobile_no} onChange={this.onChange} />
                                  </div>
                                  <div className="inner_pickupinfo">
                                    <svg  viewBox="0 0 24 24"><path d="M11.5 7a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm-4.7 4.357l4.7 7.73l4.7-7.73a5.5 5.5 0 1 0-9.4 0zm10.254.52L11.5 22.012l-5.554-9.135a6.5 6.5 0 1 1 11.11 0h-.002z" fill="#00899b"/></svg>
                                    <label><span>*</span>  {lang.address} <h6>{this.state.saddressErr}</h6></label>
                                    <input type="text" name="saddress" placeholder={lang.pickup_address} defaultValue={this.state.info.address} onChange={this.onChange} />
                                  </div>
                              </div>


                            </div>
                        <ul className="list-inline pull-right second_step">
                           <li><button type="button" className="prev-step prev-step_pick" onClick={this.paymentPrev}>{lang.previous}</button></li>
                           <li><button type="button" className="next-step next-step_pick" onClick={this.nextPayment}>{lang.next}</button></li>
                        </ul>
                     </div>
                     <div className="tab-pane" role="tabpanel" id="step3">
                        <div className="shipmentbox">
                              <div className="pickup_information">
                                  <h4>{lang.delivery_detail}</h4>
                                  <div className="inner_pickupinfo">

                                    <label><span>*</span> {lang.city_area} <h6>{this.state.destinationErr}</h6></label>
                                    <select className="form-control origin" name="destination" onChange={this.twoCalls}>
                                        <option>{lang.select}</option>
                                        { (this.state.destination_cities !=='' && this.state.destination_cities.length  > 0) ?
                                            this.state.destination_cities.map((value,index)=>{
                                                return(
                                                       <option key={index} value={value.destination}>{value.destination}</option>
                                                    );
                                            })
                                            :
                                            <option></option>
                                        }
                                    </select>
                                  </div>
                                  <div className="inner_pickupinfo">
                                    {
                                        this.state.manual_area =="1" &&
                                        <React.Fragment>
                                            <label> Areas  </label>
                                            <select className="form-control origin" name="area" onChange={(e)=>this.setState({area:e.target.value})}>
                                                { (this.state.allAreas !=='' && this.state.allAreas.length  > 0) ?
                                                    this.state.allAreas.map((value,index)=>{
                                                        return(
                                                               <option  key={index} value={value.id}>{value.area_name}</option>
                                                            );
                                                    })
                                                    :
                                                    <option></option>
                                                }
                                            </select>
                                        </React.Fragment>
                                    }
                                    
                                  </div>
                                  <div className="inner_pickupinfo">
                                    <svg viewBox="0 0 24 24"><path d="M11.5 14c4.142 0 7.5 1.567 7.5 3.5V20H4v-2.5c0-1.933 3.358-3.5 7.5-3.5zm6.5 3.5c0-1.38-2.91-2.5-6.5-2.5S5 16.12 5 17.5V19h13v-1.5zM11.5 5a3.5 3.5 0 1 1 0 7a3.5 3.5 0 0 1 0-7zm0 1a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5z" fill="#00899b"/></svg>
                                    <label><span>*</span> {lang.name}<h6>{this.state.rnameErr}</h6></label>
                                    <input type="text" name="rname" placeholder={lang.receiver_name} onChange={this.onChange} />
                                  </div>
                                  <div className="inner_pickupinfo">
                                    <svg  viewBox="0 0 24 24"><path d="M19.5 22c.827 0 1.5-.673 1.5-1.5V17c0-.827-.673-1.5-1.5-1.5c-1.17 0-2.32-.184-3.42-.547a1.523 1.523 0 0 0-1.523.363l-1.44 1.44a14.655 14.655 0 0 1-5.885-5.883L8.66 9.436c.412-.382.56-.963.384-1.522A10.872 10.872 0 0 1 8.5 4.5C8.5 3.673 7.827 3 7 3H3.5C2.673 3 2 3.673 2 4.5C2 14.15 9.85 22 19.5 22zM3.5 4H7a.5.5 0 0 1 .5.5c0 1.277.2 2.531.593 3.72a.473.473 0 0 1-.127.497L6.01 10.683c1.637 3.228 4.055 5.646 7.298 7.297l1.949-1.95a.516.516 0 0 1 .516-.126c1.196.396 2.45.596 3.727.596c.275 0 .5.225.5.5v3.5c0 .275-.225.5-.5.5C10.402 21 3 13.598 3 4.5a.5.5 0 0 1 .5-.5z" fill="#00899b"/></svg>
                                    <label><span>*</span> {lang.phone}<h6>{this.state.rphoneErr}</h6></label>
                                    <input type="number" placeholder={lang.receiver_phone} name="rphone" onChange={this.onChange} />
                                  </div>
                                  <div className="inner_pickupinfo">
                                    <svg  viewBox="0 0 24 24"><path d="M11.5 7a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm-4.7 4.357l4.7 7.73l4.7-7.73a5.5 5.5 0 1 0-9.4 0zm10.254.52L11.5 22.012l-5.554-9.135a6.5 6.5 0 1 1 11.11 0h-.002z" fill="#00899b"/></svg>
                                    <label><span>*</span>  {lang.address}<h6>{this.state.raddressErr}</h6></label>
                                    <input type="text" placeholder={lang.receiver_address} onChange={this.onChange} name="raddress" />
                                  </div>
                              </div>


                            </div>
                        <ul className="list-inline pull-right second_step">
                           <li><button type="button" className="prev-step prev-step_deliver" onClick={this.deliveryPrev}>{lang.previous}</button></li>
                           <li><button type="button" className="next-step next-step_deliver" onClick={this.nextDelivery}>{lang.next}</button></li>
                        </ul>
                     </div>
                     <div className="tab-pane" role="tabpanel" id="complete">
                        <div className="shipmentbox">
                              <div className="pickup_information">
                                  <h4> {lang.shipment_details}</h4>
                                  <div className="inner_pickupinfo">
                                    <svg  viewBox="0 0 24 24"><path d="M6 5h2.5a3 3 0 1 1 6 0H17a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3zm0 1a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-1v3H7V6H6zm2 2h7V6H8v2zm3.5-5a2 2 0 0 0-2 2h4a2 2 0 0 0-2-2zM8 19v-2H6v-1h2v-2h1v2h2v1H9v2H8z" fill="#00899b"/></svg>
                                    <label><span>*</span>{lang.item_detail} <h6>{this.state.item_detailErr}</h6></label>
                                    <input type="text" placeholder={lang.details} name="item_detail" onChange={this.onChange} />

                                  </div>
                                  <div className="inner_pickupinfo">
                                    <svg viewBox="0 0 24 24"><path d="M7 3h9a3 3 0 0 1 3 3v13a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3zm0 1a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H7zm0 1h9a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1zm0 1v12h9V6H7zm2 2h5v1H9V8zm0 3h5v1H9v-1zm0 3h3v1H9v-1z" fill="#00899b"/></svg>
                                    <label> {lang.special_instuction} <h6>{this.state.special_insErr}</h6></label>
                                    <input type="text" placeholder={lang.special_instuction} onChange={this.onChange} name="special_isntructions" />

                                  </div>
                                  <div className="inner_pickupinfo">
                                    <svg  viewBox="0 0 24 24"><path d="M13 6h1V5a1 1 0 0 0-1-1h-3a1 1 0 0 0-1 1v1h1v2.072L4.285 17.97A2 2 0 0 0 6 21h11a2 2 0 0 0 1.715-3.03L13 8.072V6zM6 22a3 3 0 0 1-2.516-4.635L9 7.811V7a1 1 0 0 1-1-1V5a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v1a1 1 0 0 1-1 1v.811l5.516 9.554A3 3 0 0 1 17 22H6z" fill="#00899b"/></svg>
                                    <label><span>*</span> {lang.weight} <h6>{this.state.weightErr}</h6></label>
                                    <input type="number" placeholder={lang.weight} defaultValue="0.5" name="weight" onChange={this.onChange} />
                                  </div>

                                  <div className="inner_pickupinfo">
                                    <svg  viewBox="0 0 24 24"><path d="M12 4h8v6h-8V4zm0 17V11h8v10h-8zm-9 0v-6h8v6H3zm0-7V4h8v10H3zm1-9v8h6V5H4zm9 0v4h6V5h-6zm0 7v8h6v-8h-6zm-9 4v4h6v-4H4z" fill="#00899b"/></svg>
                                    <label><span>*</span>  {lang.no_of_pieces} <h6>{this.state.piecesErr}</h6></label>
                                    <input type="number" placeholder={lang.no_of_pieces} defaultValue="1" name="pieces" onChange={this.onChange} />
                                  </div>
                                  <div className="inner_pickupinfo">
                                    <svg viewBox="0 0 24 24"><path d="M4 3a3 3 0 0 0-3 3v13a3 3 0 0 0 3 3h13a3 3 0 0 0 3-3v-1.77A3 3 0 0 0 21 15v-5a3 3 0 0 0-1-2.23V6a3 3 0 0 0-3-3H4zm0 1h13a2 2 0 0 1 2 2v1.174A3 3 0 0 0 18 7h-6a3 3 0 0 0-3 3v5a3 3 0 0 0 3 3h6a3 3 0 0 0 1-.174V19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2zm8 4h6a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2zm2.5 2a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5zm0 1a1.5 1.5 0 1 1 0 3a1.5 1.5 0 0 1 0-3z" fill="#00899b"/></svg>
                                    <label><span>*</span>  {lang.cod_amount} <h6>{this.state.cod_amountErr}</h6></label>
                                    <input type="number"  placeholder={lang.cod_amount} name="cod_amount" onChange={this.onChange} />
                                  </div>
                              </div>


                            </div>
                            <ul className="list-inline pull-right second_step">
                            <li><button type="button" className="prev-step prev-step_pick" onClick={this.submitPrev}>{lang.previous}</button></li>
                           <li>
                               <button className="next-step"  type="button" onClick={this.submitOrder} disabled={this.state.loading}>
                                  {this.state.loading && (
                                    <i
                                      className="fa fa-refresh fa-spin"
                                      style={{ marginRight: "5px" }}
                                    />
                                  )}
                                  {this.state.loading && <span>Loading...</span>}
                                  {!this.state.loading && <span>Submit</span>}
                                </button>
                           </li>
                        </ul>
                     </div>
                     <div className="clearfix"></div>
                  </div>

            </div>
         </form>
      </div>
   </div>
  <div className="bottom_bar right_btmbar">
    <ul>
      <li >
        <Link to="">
         <svg aria-hidden="true" focusable="false" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#fff"/></svg>
        <b>{lang.home}</b>
        </Link>
      </li>

      <li>
        <Link to="/booking">
           <svg viewBox="0 0 24 24"><path d="M7 2h1a1 1 0 0 1 1 1v1h5V3a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3V3a1 1 0 0 1 1-1zm8 2h1V3h-1v1zM8 4V3H7v1h1zM6 5a2 2 0 0 0-2 2v1h15V7a2 2 0 0 0-2-2H6zM4 18a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V9H4v9zm8-5h5v5h-5v-5zm1 1v3h3v-3h-3z" fill="#fff"/></svg>
        <b>{lang.booking}</b>
        </Link>
      </li>
      <li>
       <Link to="/orders">

          <svg  viewBox="0 0 24 24"><path d="M16 18a2 2 0 1 1 0 4a2 2 0 0 1 0-4zm0 1a1 1 0 1 0 0 2a1 1 0 0 0 0-2zm-9-1a2 2 0 1 1 0 4a2 2 0 0 1 0-4zm0 1a1 1 0 1 0 0 2a1 1 0 0 0 0-2zM18 6H4.273l2.547 6H15a.994.994 0 0 0 .8-.402l3-4h.001A1 1 0 0 0 18 6zm-3 7H6.866L6.1 14.56L6 15a1 1 0 0 0 1 1h11v1H7a2 2 0 0 1-1.75-2.97l.72-1.474L2.338 4H1V3h2l.849 2H18a2 2 0 0 1 1.553 3.26l-2.914 3.886A1.998 1.998 0 0 1 15 13z" fill="#fff"/></svg>
        <b>{lang.orders}</b>
       </Link>
      </li>
    </ul>
    </div>
</React.Fragment>
)
}
}
export default PickupRequest;
