export const french = {
    tracking_history: "Historique de tracking",
    busingess_dashboard: "Tableau de bord",
    enter_your_tracking_code: "Entrez votre traking",
    track_order: "Suivre le colis",
    get_quote: "Demander une estimation",
    contact_us: "Nous Contacter",
    service_type: "Type de service",
    city_area: "Wilaya",
    name:"Nom",
    phone: "Téléphone",
    phone_number:"Numéro de téléphone",
    address: "Adresse",
    pickup_address: "Adresse du point de ramassage",
    previous: "Precédent",
    next: "Suivant",
    delivery_detail: "Info. sur le destinataire",
    select: "Selectionner",
    areas: "Communes",
    receiver_name: "Nom du destinataire",
    receiver_phone: "Téléphone du destinataire",
    receiver_address:"Adresse du destinataire",
    shipment_details: "Détails du colis",
    item_detail: "Description du contenu",
    details: "Details",
    special_instuction: "Instructions supplémentaires",
    weight: "Poids (Kg)",
    no_of_pieces: "Nombre de pièces",
    cod_amount: "Recouvrement d'argent",
    loading: "Chargement...",
    submit: "envoyer",
    home: "Accueil",
    booking: "Ajouter un colis",
    order: "Colis",
    orders: "Colis",
    pickup_detail:"Info. expéditeur",
    enter_your_name: "Votre nom",
    enter_your_phone: "Numéro de téléphone",
    your_message: "Laisser un message ici",
    sign_up: "S'inscrire",
    personal_info: "Info. personnelle",
    company_name: "Nom d'entreprise",
    person_of_contact: "Nom du contact",
    email: "Email",
    select_city: "Selectionner une wilaya",
    cnic: "N° Carte nationale",
    cnic_copy: "Photo carte nationale",
    bank_information: "Info. compte bancaire",
    bank_name: "Nom de la bank",
    account_title: "Nom du compte",
    account_number: "Numéro de compte",
    branch_name: "Nom de la filiale",
    branch_code: "Code de la filiale",
    swift_code: "Clé n°",
    iban: "RIB",
    password: "Mot de passe",
    confirm_password:"Confirmer le mot de passe",
    dashboard: "Tableau de bord",
    lets_get_a_quick: "Voyons un bref aperçu ...",
    pending_cod_amount: "Recouvrement en attente",
    total_cod: "Montant total du recourverement",
    lets_start: "Commençons",
    plz_enter_requested_info: "Veuillez saisir les informations demandées.",
    sign_in: "Se connecter",
    dont_have_accunt: "Vous n'êtes pas inscrit ?",
    taslim_net: "distazero.com",
    tracking_no: "Numéro de Tracking",
    sender_information:"Information sur l'expéditeur",
    receiver_information: "information sur destinataire",
    order_iformation: "Information du colis",
    date: "Date",
    status:"Statut",
    order_list: "Liste de colis",
    pickup_city: "Wilaya d'origine",
    account_name: "Nom du compte client",
    bussiness_name: "Nom d'entreprise",
    destination: "Destination",
    parcel_weight: "Poids du colis",
    delivery_fees: "Tarif livraison",
    amount: "Le montant",
    no_record_found: "Aucun résultat n'a été trouvé",
    pickup_city_select: "Selectionner la wilaya d'origine",
    rate_transit: "Rates & Transit",
    select_service_type: "Selectionner le type de service",
    select_distination: "Selectionner la wilaya de destination",
    already_account: "avez-vous déjà enregistrer votre compte?",
    log_in: "Se connecter",
    soko_joint: "Distazero",
    to_me: "A moi",
    from_me: "Pour moi",
    rates_transit:"Rates and  transit",
    pickup_request: "Demande de ramassage",
    track_shipment: "Suivre les colis",
    track_ship_info:"Info. du suivi de colis",
    tracking_result: "Résultat de Tracking",
    shipper_info: "Information de l'expéditeur",
    shipper:"Expéditeur",
    origin: "Origine",
    consigne_info:"Information du destinataire",
    save: "enregistrer",
    track_info:"Info Tracking",
    tracking_list:"Liste de Tracking",
    track_your_pakage:"Suivre votre colis ici",
    track_up_to:"Vous pouvez suivre jusqu'à 10 colis à la fois. Separer vos numéros de tracking par une virgule (,)",
    let_us_know:"Faites-nous part de vos commentaires, questions ou problèmes concernant l'application ou les fonctionnalités.",
    logo_img: "Image du logo",
    shipping_info: "Informations sur l'expediteur",
    web_link: "Url de votre siteweb",
    account_nature:"Sélectionnez",
    cash_on_deli: "Cash on Delivery",
    coprate_invoices: "Corporate Invoicing",
    product_type: "Type de produits",
    apparel: "vêtements",
    auto_pants: "Accessoires auto.",
    expected_avrg: "Estimation du nombre d'envois en moyenne par mois ",
    month: "Mois",
    accessories: "Accessories",
    gadgets:"Gadgets",
    cosmetics: "Cosmetiques",
    jewellry:"Montres et Bijoux",
    stationary:"Immobilier",
    handicraft: "Artisanat",
    footwear: "Chaussures",
    organic: "Produits Organiques",
    health_pro: "Produits de santé",
    applience_electronics: "Electroménager",
    home_decore:"Decors de maison",
    toys: "Jouers",
    fitness: "Articles de sport",
    market_place: "MarketPlace",
    document:"Document",
    letter: "Lettres",
    other:"Autres",
    order_src: "Source du colis:",
    dream_window:"Dream Windows",
    boumaref:"Boumaref",
    tracking: "Tracking",
    scan_pickup:"Scanner le ramassage",
    scan_delivery: "Scanner la livraison",
    search_here : "Rechercher",
    kg:"Kg",
}